import { createApp } from 'vue';
import App from './App.vue';
import router from '@/router';
import store from '@/store';
import '@/styles/index.scss';
import lazyBg from '@/directives/lazyBg';
//import ElementPlus from 'element-plus';
//import 'element-plus/dist/index.css'

createApp(App).use(router).use(store).use(lazyBg).mount('#app');
