import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withModifiers as _withModifiers, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { style: {"width":"100%","background":"#fff"} }
const _hoisted_2 = { class: "header relative" }
const _hoisted_3 = ["src"]
const _hoisted_4 = {
  class: "nav-wrapper",
  style: {"margin-left":"200px"}
}
const _hoisted_5 = ["onClick", "onMouseenter", "onMouseleave"]
const _hoisted_6 = { class: "telephone common-height" }
const _hoisted_7 = ["src"]
const _hoisted_8 = { class: "nav-children" }
const _hoisted_9 = { class: "nav-second-level nav-second-level-product flex flex-wrap" }
const _hoisted_10 = ["onClick"]
const _hoisted_11 = ["src"]
const _hoisted_12 = { class: "col-b-title f-w-500 f-s-14 h-20 l-h-20" }
const _hoisted_13 = { class: "col-b-content f-w-400 f-s-12 h-17 l-h-17 m-t-7" }
const _hoisted_14 = { class: "nav-children" }
const _hoisted_15 = { class: "nav-second-level-plan flex flex-wrap" }
const _hoisted_16 = ["onClick"]
const _hoisted_17 = { class: "need-scale brs10" }
const _hoisted_18 = ["src"]
const _hoisted_19 = { class: "col-b-title f-w-500 f-s-14 h-20 l-h-20 m-t-10" }
const _hoisted_20 = { class: "nav-second-level-about" }
const _hoisted_21 = ["onClick"]
const _hoisted_22 = { class: "f-s-14 f-w-400" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass(["header-wrap", {["header-wrap-production"]: _ctx.dialogVisible && _ctx.isProduction, ["header-wrap-plan"]: _ctx.dialogVisible &&
		 _ctx.isPlan}]),
      onMouseenter: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleHeaderEnter && _ctx.handleHeaderEnter(...args))),
      onMouseleave: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.handleHeaderLeave && _ctx.handleHeaderLeave(...args)))
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", {
            class: "logo common-height justify-start absolute",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.routeToHome && _ctx.routeToHome(...args))),
            style: {"left":"-24px","width":"201px"}
          }, [
            _createElementVNode("img", {
              src: _ctx.logoUrl,
              class: "w-100p h-100p"
            }, null, 8, _hoisted_3)
          ]),
          _createElementVNode("div", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.navs, (item) => {
              return (_openBlock(), _createElementBlock("div", {
                key: item.name,
                class: _normalizeClass(["nav-item common-height pointer", { [`active-bottom`]: _ctx.hasActiveBottom(item), [`active-nav`]:
					 _ctx.$store.state.activeNav === item.name }]),
                onClick: _withModifiers(($event: any) => (_ctx.handleNavClick(item)), ["stop"]),
                onMouseenter: _withModifiers(($event: any) => (_ctx.handleMouseOver(item)), ["stop"]),
                onMouseleave: _withModifiers(($event: any) => (_ctx.handleMouseOut(item)), ["stop"])
              }, _toDisplayString(item.display), 43, _hoisted_5))
            }), 128))
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("img", {
              src: _ctx.customerUrl,
              class: "w-17 h-16 m-r-11"
            }, null, 8, _hoisted_7),
            _createElementVNode("span", null, _toDisplayString(_ctx.phoneOf400), 1)
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_8, [
        _withDirectives(_createElementVNode("div", _hoisted_9, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.productionList, (item) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "child-item flex align-center pointer",
              key: item.key,
              onClick: ($event: any) => (_ctx.handleNavChildClick(_ctx.navs[1],item))
            }, [
              _createElementVNode("img", {
                src: item.url,
                class: "m-l-6 w-48 h-48 brs10 bg-b-blue m-r-16"
              }, null, 8, _hoisted_11),
              _createElementVNode("div", null, [
                _createElementVNode("div", _hoisted_12, _toDisplayString(item.name), 1),
                _createElementVNode("div", _hoisted_13, _toDisplayString(item.desc), 1)
              ])
            ], 8, _hoisted_10))
          }), 128))
        ], 512), [
          [_vShow, _ctx.dialogVisible &&
			_ctx.isProduction]
        ])
      ]),
      _createElementVNode("div", _hoisted_14, [
        _withDirectives(_createElementVNode("div", _hoisted_15, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.planList, (item) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "child-item flex-col pointer m-r-20",
              key: item.key,
              onClick: ($event: any) => (_ctx.handleNavChildClick(_ctx.navs[2],item))
            }, [
              _createElementVNode("div", _hoisted_17, [
                _createElementVNode("img", {
                  src: item.url,
                  class: "w-220 h-124 brs10"
                }, null, 8, _hoisted_18)
              ]),
              _createElementVNode("div", _hoisted_19, _toDisplayString(item.name), 1)
            ], 8, _hoisted_16))
          }), 128))
        ], 512), [
          [_vShow, _ctx.dialogVisible && _ctx.isPlan]
        ])
      ]),
      _withDirectives(_createElementVNode("div", {
        class: "nav-children-about",
        onMouseenter: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleNavSecondEnter && _ctx.handleNavSecondEnter(...args)))
      }, [
        _createElementVNode("div", _hoisted_20, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.aboutList, (item) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "pointer child-item",
              key: item.key,
              onClick: ($event: any) => (_ctx.handleNavChildClick(_ctx.navs[6],item))
            }, [
              _createElementVNode("div", _hoisted_22, _toDisplayString(item.name), 1)
            ], 8, _hoisted_21))
          }), 128))
        ])
      ], 544), [
        [_vShow, _ctx.dialogVisible && _ctx.isAbout]
      ])
    ], 34),
    _withDirectives(_createElementVNode("div", {
      class: _normalizeClass(["nav-cover", {["nav-cover-production"]: _ctx.isProduction,["nav-cover-plan"]: _ctx.isPlan}])
    }, null, 2), [
      [_vShow, _ctx.dialogVisible &&
	(_ctx.isPlan || _ctx.isProduction)]
    ])
  ], 64))
}