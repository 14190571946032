import { MetaRoot, MetaHTMLElement } from '@/types/meta';


export function useMeta() {

	const query = (key: string) => {
		return document.querySelector(key) as HTMLElement;
	};

	// 插入到第一个link前
	const getReferenceNode = () => {
		return query('link');
	};

	const getParentNode = () => {
		const childNode = getReferenceNode() as HTMLElement;
		return childNode.parentNode;
	};

	const removeNode = (removeNode: HTMLElement) => {
		const parent = getParentNode() as HTMLElement;
		parent.removeChild(removeNode);
	};

	const insertNode = (inserted: HTMLElement) => {
		const parent = getParentNode() as HTMLElement;
		const reference = getReferenceNode() as HTMLElement;
		parent.insertBefore(inserted, reference);
	};
	const createElement = (tag: string) => {
		return document.createElement(tag);
	};
	// 更新meta信息
	const updateMeta = (metaInfo: MetaRoot) => {
		try{
			const names = metaInfo.meta.map(item => item.name);
			// 1.删除title
			const oldTitleNode = query('title');
			removeNode(oldTitleNode);

			// 2.删除meta
			names.forEach(item => {
				const oldMetaNode = query('meta[name=" ' + item + '"]');
				if (oldMetaNode)
				removeNode(oldMetaNode);
			});

			// 3.创建meta
			metaInfo.meta.forEach(item => {
				let newMetaNode = createElement('meta') as MetaHTMLElement;
				newMetaNode.name = item.name;
				newMetaNode.content = item.content;
				insertNode(newMetaNode);
			});

			// 4.创建title
			let newTitleNode = createElement('title');
			newTitleNode.innerHTML = metaInfo.title;
			insertNode(newTitleNode);
		} catch (e) {
			console.log(e)
		}

	};
	return { updateMeta };
}

export default useMeta;