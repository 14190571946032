
import { computed, defineComponent, ref, watch, onMounted } from 'vue';
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import Title from '@/components/Title.vue';
import { useWindowScroll } from '@vueuse/core';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import useMeta from '@/hooks/meta';

export default defineComponent({
	name: 'App',
	components: {
		Header,
		Footer,
		Title
	},
	setup() {

		let scrollDirect = ref('');
		let scrollType = ref('');

		const { y } = useWindowScroll();

		const setScrollType = (type: string) => {
			scrollType.value = type;
		};

		const store = useStore();
		const { updateMeta } = useMeta();
		const configGet = (data: any[], key: string) => {
			const find = data.find((item: any) => item.name === key) || {};
			return find.value;
		};
		store.dispatch('getConfigList')
			.then(res => {
				const metaInfo = {
					title: configGet(res,'website-title'),
					meta: [
						{ name: 'keywords', content: configGet(res,'website-keyword') },
						{ name: 'description', content: configGet(res,'website-desc') }
					]
				};
				updateMeta(metaInfo);
			})
			.catch(error => {
			});

		const route = useRoute();
		const hasTitle = computed(() => {
			return route.meta?.hasTitle;
		});

		watch(y, (newVal, prevVal) => {

			if (newVal >= 0 && prevVal >= 0) {
				// 手动调用window.scroll的时候阻止本次改变
				if (scrollType.value === 'window-api') {
					scrollType.value = '';
				} else {
					scrollDirect.value = newVal > prevVal ? newVal >= 60 ? 'down' : '' : 'up';
				}
			}
		});

		onMounted(()=>{
			document.dispatchEvent(new Event('render-event'))
		})

		return { y, scrollDirect, hasTitle, route, setScrollType };
	}
});
