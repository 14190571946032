import { App } from 'vue';
import { ObjectDirective } from '@vue/runtime-core';
import { useIntersectionObserver } from '@vueuse/core';
import { loadImg } from '@/utils/tools';

interface LazyBg extends ObjectDirective {
	install: (app: App) => any;
}

const lazyBg: LazyBg = {
	mounted(el: HTMLElement, binding) {
		// 默认图片
		const defaultImg = require('@/assets/static.png');
		// 指令传入的参数，字符串|数组
		// 数组 [params1，params2，params3]：低倍图，是否自动加载高倍图（可选），高倍图地址（可选）
		const directiveParams = binding.value;

		let directiveImg = directiveParams;
		let isSwitch = false;
		let clearnessImg = '';
		if (Array.isArray(directiveParams) && directiveParams.length > 2) {
			directiveImg = directiveParams[0];
			isSwitch = directiveParams[1];
			clearnessImg = directiveParams[2];
		}
		// 设置默认
		el.style.backgroundImage = `url(${defaultImg})`;

		const { stop } = useIntersectionObserver(el, ([{ isIntersecting }]) => {
			if (isIntersecting) {
				if (directiveImg) {
					el.style.backgroundImage = `url(${directiveImg})`;
					if (isSwitch) {
						el.style.filter = 'blur(4px)';
					}

					if (isSwitch) {
						loadImg(clearnessImg).then(() => {
							el.style.backgroundImage = `url(${clearnessImg})`;
							el.style.filter = 'blur(0)';
							//el.style.transition = 'all 0.5s';
						});
					}
				}
				//停止监听
				stop();
			}
		});
	},
	install(Vue: App<Element>) {
		Vue.directive('lazy-bg', lazyBg);
	}
};

export default lazyBg;
