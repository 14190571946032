export const PLAN_CATEGORY_ROOM_BUILD = 'PLAN_CATEGORY_ROOM_BUILD';
export const PLAN_CATEGORY_THREE_LESSON_BUILD = 'PLAN_CATEGORY_THREE_LESSON_BUILD';
export const PLAN_CATEGORY_ROOM_UPGRADE = 'PLAN_CATEGORY_ROOM_UPGRADE';
export const PLAN_CATEGORY_ROOM_SYSTEM = 'PLAN_CATEGORY_ROOM_SYSTEM';
export const PLAN_CATEGORY_ROOM_SOLUTION = 'PLAN_CATEGORY_ROOM_SOLUTION';
export const PLAN_CATEGORY_LIST = [
	{
		name: '智慧教室建设方案',
		key: PLAN_CATEGORY_ROOM_UPGRADE,
		desc: '',
		url: require('@/assets/plan_category_room_build.png'),
	},
	{
		name: '混合式课堂解决方案',
		desc: '',
		url: require('@/assets/plan/plan_category_room_solution.png'),
		key: PLAN_CATEGORY_ROOM_SOLUTION,
	},
	{
		name: '可以时光倒流的智慧实训系统',
		desc: '',
		url: require('@/assets/plan/plan_category_room_system.png'),
		key: PLAN_CATEGORY_ROOM_SYSTEM,
	},
	{
		name: '"三个课堂"建设方案',
		desc: '',
		url: require('@/assets/plan_category_three_lesson.png'),
		key: PLAN_CATEGORY_THREE_LESSON_BUILD,
	},
	{
		name: '传统教室升级改造方案',
		desc: '',
		url: require('@/assets/plan_category_room_upgrade.png'),
		key: PLAN_CATEGORY_ROOM_BUILD,
	},

]