let prefix = '/api',
	ip = window.location.hostname,
	protocol = window.location.protocol;
if (process.env.NODE_ENV === 'development') {
	prefix = '/dx/cross/api';
}

export const URL = `${prefix}/`;
export const BASE_URL = process.env.NODE_ENV === 'development' ? URL : `https://www.etah-tech.com${prefix}/`;

