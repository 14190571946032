import { RootState } from '@/types/store';
import { createStore } from 'vuex';
import { getConfigList } from '@/api/config';

const store = createStore({
	state() {
		return {
			count: 0,
			activeNav: '',
			activeTitle: sessionStorage.getItem('activeTitle') || '',
			configList: [],
		};
	},
	mutations: {
		increment(state: RootState) {
			state.count++;
		},
		setActiveNav(state: RootState, params: string) {
			state.activeNav = params;
		},
		setActiveTitle(state: RootState, params: string) {
			sessionStorage.setItem('activeTitle', params);
			state.activeTitle = params;
		},
		setConfigList(state: RootState, data: object) {
			state.configList = data;
		},
	},
	actions: {
		increment(content, data) {
			content.commit('increment');
		},
		// 设置一级导航高亮
		setActiveNav(content, val) {
			content.commit('setActiveNav', val);
		},
		setActiveTitle(content, val) {
			content.commit('setActiveTitle', val);
		},
		getConfigList(content, params) {
			return new Promise(resolve => {
				getConfigList()
					.then(res=> {
						const {error_code , data} = res;
						if (error_code === 0) {
							resolve(data)
							content.commit('setConfigList',data)
						} else {
							resolve([])
							content.commit('setConfigList',[])
						}
					})
					.catch(error=> {
						resolve([])
						content.commit('setConfigList',[])
					})
			})
		}
	}
});

export default store;