import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router';
import { ROUTE_ABOUT, ROUTE_PLAN, ROUTE_PRODUCTION, ROUTE_JOIN, ROUTE_NEWS, ROUTE_PROJECT,ROUTE_HOME, ROUTE_NEW_DETAIL,ROUTE_PROJECT_DETAIL, ROUTE_JOIN_DETAIL } from '@/utils/route';

const router = createRouter({
	history: createWebHistory(),
	routes: [
		{
			path: '/',
			redirect: '/home'
		},
		{
			path: '/home',
			name: ROUTE_HOME,
			component: () => import(/* webpackChunkName: "home" */ '../views/home/index.vue'),
			meta: { hasTitle: false }
		},
		// 产品中心
		{
			path: '/production/:key',
			name: ROUTE_PRODUCTION,
			component: () => import(/* webpackChunkName: "production" */ '../views/production/index.vue'),
			meta: { hasTitle: true }
		},
		// 方案中心
		{
			path: '/plan/:key',
			name: ROUTE_PLAN,
			component: () => import(/* webpackChunkName: "plan" */ '../views/plan/index.vue'),
			meta: { hasTitle: true }
		},
		// 案例中心
		{
			path: '/project',
			name: ROUTE_PROJECT,
			component: () => import(/* webpackChunkName: "project" */ '../views/project/index.vue'),
			meta: { hasTitle: true, title: '案例中心' }
		},
		{
			path: '/projectDetail',
			name: ROUTE_PROJECT_DETAIL,
			component: () => import(/* webpackChunkName: "projectDetail" */ '../views/project/projectDetail/index.vue'),
			meta: { hasTitle: true, title: '案例中心' }
		},
		// 新闻中心
		{
			path: '/news',
			name: ROUTE_NEWS,
			component: () => import(/* webpackChunkName: "news" */ '../views/news/index.vue'),
			meta: { hasTitle: true, title: '企业动态' }
		},
        {
            path: '/news/newDetail',
            name: ROUTE_NEW_DETAIL,
            component: () => import(/* webpackChunkName: "newDetail" */ '../views/news/newDetail/index.vue'),
			meta: { hasTitle: true, title: '企业动态' }
        },
		// 加入我们
		{
			path: '/join',
			name: ROUTE_JOIN,
			component: () => import(/* webpackChunkName: "join" */ '../views/join/index.vue'),
			meta: { hasTitle: true, title: '加入我们'  }
		},
		{
			path: '/join/joinDetail',
			name: ROUTE_JOIN_DETAIL,
			component: () => import(/* webpackChunkName: "joinDetail" */ '../views/join/joinDetail/index.vue'),
			meta: { hasTitle: true, title: '加入我们' }
		},
		// 关于我们
		{
			path: '/about/:key',
			name: ROUTE_ABOUT,
			component: () => import(/* webpackChunkName: "about" */ '../views/about/index.vue'),
			meta: { hasTitle: true }
		}
	],
	scrollBehavior (to, from, savedPosition) {
		// return 期望滚动到哪个的位置
		return{top:0}
	}
});

export default router;