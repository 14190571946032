import { computed } from 'vue';
import { useStore } from 'vuex';

export function configList() {

	const configGet = (name: string) => {
		const store = useStore();
		console.log('configGet',store)
		const find = store.state.configList.find((item: any) => item.name === name) || {};
		return find.value;
	};

	const phoneOf400 = computed(() => {
		return configGet('400-phone');
	});

	const phoneOf027 = computed(() => {
		return configGet('027-phone');
	});

	const address = computed(() => {
		return configGet('address');
	});

	const websiteTitle = computed(() => {
		return configGet('website-title');
	});

	const websiteMetaKeyword = computed(() => {
		return configGet('website-keyword');
	});

	const websiteMetaDesc = computed(() => {
		return configGet('website-description');
	});

	return { phoneOf027, phoneOf400, address, websiteTitle, websiteMetaKeyword, websiteMetaDesc };
}

export default configList