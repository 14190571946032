import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "app-header" }
const _hoisted_2 = {
  key: 0,
  class: "app-title"
}
const _hoisted_3 = { class: "app-main" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_Title = _resolveComponent("Title")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({["is-sticky"]: _ctx.scrollDirect === "down", ["has-title"]: _ctx.hasTitle})
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_Header, {
        y: _ctx.y,
        onScrollType: _ctx.setScrollType
      }, null, 8, ["y", "onScrollType"])
    ]),
    (_ctx.hasTitle)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_Title, {
            title: _ctx.route.meta?.title
          }, null, 8, ["title"])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_router_view)
    ]),
    _createVNode(_component_Footer)
  ], 2))
}