import { PRODUCTION_CATEGORY_LIST } from '@/utils/production';
import { ROUTE_ABOUT, ROUTE_JOIN, ROUTE_NEWS, ROUTE_PLAN, ROUTE_PRODUCTION, ROUTE_PROJECT } from '@/utils/route';
import { PLAN_CATEGORY_LIST } from '@/utils/plan';

export const COMPANY_CATEGORY_DESC = 'COMPANY_CATEGORY_DESC';
export const COMPANY_CATEGORY_CONTACT = 'COMPANY_CATEGORY_CONTACT';
export const COMPANY_CATEGORY_LIST = [
	{
		name: '公司简介',
		key: COMPANY_CATEGORY_DESC,
		desc: '',
		url: '',
	},
	{
		name: '联系我们',
		desc: '',
		url: '',
		key: COMPANY_CATEGORY_CONTACT,
	},
]


export const FOOTER_NAV_LIST = [
	{ name: '产品中心', children: PRODUCTION_CATEGORY_LIST, route: ROUTE_PRODUCTION },
	{ name: '方案中心', children: PLAN_CATEGORY_LIST, route: ROUTE_PLAN },
	{ name: '案例中心', children: [{ name: '经典案例', key: '' }], route: ROUTE_PROJECT },
	{ name: '企业动态', children: [{ name: '企业动态', key: '' }], route: ROUTE_NEWS },
	{ name: '加入我们', children: [{ name: '工作岗位', key: '' }], route: ROUTE_JOIN },
	{ name: '关于我们', children: COMPANY_CATEGORY_LIST, route: ROUTE_ABOUT }
];