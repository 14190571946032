
import { defineComponent, computed } from 'vue';
import {
	ROUTE_JOIN,
	ROUTE_NEWS,
	ROUTE_PROJECT,
	ROUTE_HOME
} from '@/utils/route';
import { FOOTER_NAV_LIST } from '@/utils/company';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import configList from '@/hooks/config';

export default defineComponent({
	name: 'Footer',
	data: () => ({
		linkList: FOOTER_NAV_LIST,
		qrcodeUrl: require('@/assets/qrcode.png')
	}),
	setup() {
		const router = useRouter(), store = useStore();
		const currentYear = (new Date()).getFullYear();

		const handleNavLinkClick = (category: any, child: any) => {
			const access = [ROUTE_HOME, ROUTE_PROJECT, ROUTE_NEWS, ROUTE_JOIN];
			if (access.includes(category.route)) {
				router.push({ name: category.route });
			} else {
				// 设置二级标题
				store.dispatch('setActiveTitle', child.name);
				router.push({ path: `/${category.route}/${child.key}` });
			}
		};

		const handleClick = () => {
			window.open('http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=42018502003413', '_blank');
		};

		const { phoneOf400 } = configList();
		return {
			currentYear,
			handleNavLinkClick,
			phoneOf400,
			handleClick
		};
	}
});
