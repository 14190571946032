import axios from 'axios';

axios.defaults.withCredentials = false;
axios.defaults.timeout = 10000;

axios.interceptors.response.use(res => {

	if (res.status !== 200) {
		return Promise.reject(res.data);
	}

	return res.data;
});

export default axios;