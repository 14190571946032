export const PRODUCTION_CATEGORY_8500 = 'PRODUCTION_CATEGORY_8500';
export const PRODUCTION_CATEGORY_SMART_TEACHER = 'PRODUCTION_CATEGORY_SMART_TEACHER';
export const PRODUCTION_CATEGORY_3D = 'PRODUCTION_CATEGORY_3D';
export const PRODUCTION_CATEGORY_THREE_LESSON = 'PRODUCTION_CATEGORY_THREE_LESSON';
export const PRODUCTION_CATEGORY_WIFI_DEV = 'PRODUCTION_CATEGORY_WIFI_DEV';
export const PRODUCTION_CATEGORY_GROUPS = 'PRODUCTION_CATEGORY_GROUPS';
export const PRODUCTION_CATEGORY_PLATFORM = 'PRODUCTION_CATEGORY_PLATFORM';
export const PRODUCTION_CATEGORY_TRAIN_CART='PRODUCTION_CATEGORY_TRAIN_CART'
export const PRODUCTION_CATEGORY_SMART_BLACKBOARD='PRODUCTION_CATEGORY_SMART_BLACKBOARD'
export const PRODUCTION_CATEGORY_AI_CAMERA='PRODUCTION_CATEGORY_AI_CAMERA'
export const PRODUCTION_CATEGORY_LIST = [
	{
		name: '智慧教室终端',
		key: PRODUCTION_CATEGORY_8500,
		desc: '1块屏幕+1台核心设备=1间智慧教室',
		url: require('@/assets/production_category_8500.png'),
	},
	{
		name: '混合式教学终端',
		key: PRODUCTION_CATEGORY_SMART_TEACHER,
		desc: '超级9合1，小身材，大用处',
		url: require('@/assets/production_smart_teacher.png'),
	},
	{
		name: '"三个课堂"互动录播终端',
		desc: '支持H.323和SIP标准通讯协议的一体化终端',
		url: require('@/assets/production_category_three_lesson.png'),
		key: PRODUCTION_CATEGORY_THREE_LESSON,
	},
	{
		name: '实训推车',
		desc: '可以时光倒流的实训推车，让学生看得清、看得全…',
		url: require('@/assets/production_train_cart.png'),
		key: PRODUCTION_CATEGORY_TRAIN_CART,
	},
	{
		name: '智慧板书',
		desc: '坐在后排能看得清黑板，没抄上笔记也不用着急',
		url: require('@/assets/production_smart_blackboard.png'),
		key: PRODUCTION_CATEGORY_SMART_BLACKBOARD,
	},
	{
		name: '复眼AI摄像机',
		desc: '小学课堂人脸识别率＞99%，真正做到“一个都不能少”',
		url: require('@/assets/production_ai_camera.png'),
		key: PRODUCTION_CATEGORY_AI_CAMERA,
	},
	{
		name: '3D传感器',
		desc: '让自动录播媲美人工拍摄的黑科技',
		url: require('@/assets/production_category_3D.png'),
		key: PRODUCTION_CATEGORY_3D,
	},
	{
		name: '无线便携互动终端',
		desc: '0布线 5分钟完成搭建',
		url: require('@/assets/production_category_wifi_dev.png'),
		key: PRODUCTION_CATEGORY_WIFI_DEV,
	},
	{
		name: '小组讨论终端',
		desc: '免装 APP，小组讨论更自由',
		url: require('@/assets/production_category_group.png'),
		key: PRODUCTION_CATEGORY_GROUPS,
	},
	{
		name: '融合平台',
		desc: '融合多个管理、教学应用，支持公有/私有云部署',
		url: require('@/assets/production_category_platform.png'),
		key: PRODUCTION_CATEGORY_PLATFORM,
	}
]
