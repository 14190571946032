
import { defineComponent } from 'vue';
import { PRODUCTION_CATEGORY_LIST } from '@/utils/production';
import { PLAN_CATEGORY_LIST } from '@/utils/plan';
import { COMPANY_CATEGORY_LIST } from '@/utils/company';
import {
	ROUTE_ABOUT,
	ROUTE_PLAN,
	ROUTE_PRODUCTION,
	ROUTE_HOME,
	ROUTE_NEWS,
	ROUTE_JOIN,
	ROUTE_PROJECT
} from '@/utils/route';

interface RouteItem {
	name?: string,
	children?: object
}

interface TimerRoot {
	[key: string]: undefined | number;
}

export default defineComponent({
	name: 'Header',
	components: {},
	props: {
		y: {
			default: 0,
			type: Number
		}
	},
	emits: ['scrollType'],
	data: () => ({
		logoUrl: require('@/assets/logo.png'),
		customerUrl: require('@/assets/customer.png'),
		navs: [
			{ name: ROUTE_HOME, children: false, display: '首页' },
			{ name: ROUTE_PRODUCTION, children: true, display: '产品中心' },
			{ name: ROUTE_PLAN, children: true, display: '方案中心' },
			{ name: ROUTE_PROJECT, children: false, display: '案例中心' },
			{ name: ROUTE_NEWS, children: false, display: '企业动态' },
			{ name: ROUTE_JOIN, children: false, display: '加入我们' },
			{ name: ROUTE_ABOUT, children: true, display: '关于我们' }
		],
		hoverItem: {} as RouteItem,
		dialogVisible: false,
		productionList: PRODUCTION_CATEGORY_LIST,
		planList: PLAN_CATEGORY_LIST,
		aboutList: COMPANY_CATEGORY_LIST,
		historyScrollY: 0,
		timer: {} as TimerRoot
	}),
	computed: {
		isProduction() {
			return this.hoverItem.name === ROUTE_PRODUCTION;
		},
		isPlan() {
			return this.hoverItem.name === ROUTE_PLAN;
		},
		isAbout() {
			return this.hoverItem.name === ROUTE_ABOUT;
		},
		phoneOf400() {
			const find = this.$store.state.configList.find((item: any) => item.name === '400-phone') || {};
			return find.value;
		}
	},
	methods: {
		routeToHome() {
			this.$router.push('/');
		},
		hasActiveBottom(item: any) {
			return item.bottom && this.$store.state.activeNav === item.name;
		},
		handleHeaderEnter() {
			console.log('handleHeaderEnter');
			this.dialogVisible = true;

			this.historyScrollY = this.y;
		},
		handleHeaderLeave() {
			console.log('handleHeaderLeave');
			this.dialogVisible = false;
			this.freeCoverStyle(this.historyScrollY);
		},
		handleMouseOver(item: RouteItem) {
			console.log('handleMouseOver');
			this.hoverItem = item;

			if (this.isProduction || this.isPlan) {
				this.setCoverStyle();
			}
		},
		handleMouseOut(item: RouteItem) {
			console.log('handleMouseOut');
			// this.hoverItem = {};
			if (item.name === 'about') {
				this.setTimer('first-mouse-out', () => {
					this.hoverItem = {};
				});
			}
		},
		handleNavSecondEnter() {
			this.clearTimer('first-mouse-out');
		},
		// 一级导航点击事件
		handleNavClick(item: any) {
			// 一级导航只有hover active
			// this.$store.dispatch('setActiveNav', item.name);
			const access = [ROUTE_HOME, ROUTE_PROJECT, ROUTE_NEWS, ROUTE_JOIN];
			if (access.includes(item.name)) {
				this.$router.push({ name: item.name });
				this.historyScrollY = 0;
			}
		},
		// 二级导航点击事件
		handleNavChildClick(category: any, child: any) {
			// 设置二级标题
			this.$store.dispatch('setActiveTitle', child.name);
			this.$router.push({ path: `/${category.name}/${child.key}` });

			this.historyScrollY = 0;
			this.dialogVisible = false;
		},
		setTimer(key: string, fn: TimerHandler) {
			this.clearTimer(key);
			this.timer[key] = setTimeout(fn);
		},
		clearTimer(key: string) {
			if (this.timer[key]) {
				clearTimeout(this.timer[key]);
				this.timer[key] = undefined;
			}
		},
		// 设置蒙层
		setCoverStyle() {
			const dom: HTMLElement = document.querySelector('body') as HTMLElement;
			dom.style.position = 'fixed';
			dom.style.top = -this.historyScrollY + 'px';
			dom.style.overflowY = 'hidden';
		},
		// 解除蒙层
		freeCoverStyle(scrollY: number = 0, flag = true) {
			const dom: HTMLElement = document.querySelector('body') as HTMLElement;
			dom.style.position = '';
			dom.style.overflowY = 'scroll';
			if (flag) this.setPageScroll(scrollY);
		},
		setPageScroll(scrollY: number = 0) {
			this.$emit('scrollType', 'window-api');
			console.log('setPageScroll', scrollY);
			window.scrollTo(0, scrollY);
		}
	},
	beforeDestroy() {
		this.clearTimer('first-mouse-out');
	}
});
